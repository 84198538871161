import React, {forwardRef, useImperativeHandle, useMemo} from "react";
import styles from "./filter.module.css";

import {Checkbox, CheckboxProps} from "antd";
import {GetSectorsAPIParams, SectorTypeV2} from "../../../api/apiTypes";
import {CheckboxGroupProps} from "antd/es/checkbox";
import Lottie from "lottie-react";
import spinner from "../../../assets/lotties/spinner.json";
import {queries} from "../../../api/queries";
import {useTranslation} from "react-i18next";
import useSWR from "swr";
import {fetcher} from "../../../services/http.service";
import {objToQueryString} from "../../../fns/commonFns";

interface Props extends CheckboxGroupProps {
  params: GetSectorsAPIParams;
  onSearch: (value: string[]) => void;
}

const SectorFilter = forwardRef(({params, onSearch, value, ...rest}: Props, ref) => {
  const {t} = useTranslation(["shipping"]);
  const paramString = useMemo(() => {
    if (!params) {
      params = {};
    }
    const {
            designated_sector__code__in, ...filtered
          } = params;
    return objToQueryString(filtered, true);
  }, [params]);
  
  const {
          data: sectors, isLoading, mutate
        } = useSWR<SectorTypeV2[]>(queries.shipping.sectors + `?${paramString}`, fetcher);
  
  console.log("SectorFilter params:", params, sectors);
  const allCode = useMemo(() => {
    if (sectors && sectors.length) {
      return sectors.map(({designated_sector__code}) => designated_sector__code);
    }
    return [];
  }, [sectors]);
  
  const checkAll = value && sectors && sectors.length === value.length;
  
  useImperativeHandle(ref, () => ({
    mutate,
  }), []);
  
  const handleCheckAll: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked) onSearch(allCode); else onSearch([]);
  };
  
  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("checked = ", checkedValues);
    onSearch(checkedValues);
  };
  
  return (<div className={"column flex"}>
    
    <div className={"row space-between filterTitle"}>
      <span className={"body black85 "}>{t("sector code")}</span>
      <Checkbox className={styles.checkAll} onChange={handleCheckAll}
                checked={checkAll}>
        전체
      </Checkbox>
    </div>
    <Checkbox.Group
      className={styles.container + " column"}
      value={value}
      onChange={handleChangeChecked}
    >
      {sectors && sectors.length ? sectors.map((s, idx) => (<Checkbox
        key={"check" + idx}
        className={styles.check}
        data-cy="homeManageCheckbox"
        value={s.designated_sector__code}
      >
        {`${s.designated_sector__code}(${s.count})`}
      </Checkbox>)) : (isLoading ? <Lottie autoplay animationData={spinner} loop style={{height: 25}} /> : <span className={styles.nodata}>데이터가 없습니다</span>)}
    </Checkbox.Group>
  </div>);
});

export default SectorFilter;
