import React, {useMemo} from "react";
import styles from "./filter.module.css";

import {Checkbox, CheckboxProps} from "antd";
import {CheckboxGroupProps} from "antd/es/checkbox";
import {useDataAll} from "../../../api/useDataAll";
import {queries} from "../../../api/queries";
import {SellerBatchType} from "../../../api/apiTypes";
import {useTranslation} from "react-i18next";
import Lottie from "lottie-react";
import spinner from "../../../assets/lotties/spinner.json";

interface Props extends CheckboxGroupProps {
  onSearch: (value: string[]) => void;
}

const SellerBatchFilter = ({onSearch, value}: Props) => {
  const {t} = useTranslation(["shipping"]);
  const {listData, isLoading} = useDataAll<SellerBatchType>(queries.shipping.batches, {ordering: '-round'});
  const checkAll = value && listData?.length > 0 && listData?.length === value.length;
  
  console.log("SellerBatchFilter = ", listData);
  const allUuid = useMemo(() => {
    if (listData) {
      return listData.map((batch) => batch.uuid);
    }
    return [];
  }, [listData]);
  
  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("checked = ", checkedValues);
    onSearch(checkedValues);
  };
  
  const handleCheckAll: CheckboxProps['onChange'] = (e) => {
    console.log("handleCheckAll = ", e, allUuid);
    if (e.target.checked) onSearch(allUuid); else onSearch([]);
  };
  
  return (<div className={"column flex"}>
    <div className={"row space-between filterTitle"}>
      <span className={"body black85 "}>{t("batch")}</span>
      <Checkbox className={styles.checkAll} onChange={handleCheckAll}
                checked={checkAll}>
        전체
      </Checkbox>
    </div>
    <Checkbox.Group
      className={styles.container + " column " + styles.checkGroup}
      value={value}
      onChange={handleChangeChecked}
    >
      {listData && listData.length ? listData.map((s, idx) => (<Checkbox
        key={"chek" + idx}
        className={styles.check}
        data-cy="homeManageCheckbox"
        value={s.uuid}
      >
        {`${s.seller_name} ${s.round}(${s.return_count + s.shipping_count})`}
      </Checkbox>)) : (isLoading ? <Lottie autoplay animationData={spinner} loop style={{height: 25}} /> : <span className={styles.nodata}>데이터가 없습니다</span>)}
    </Checkbox.Group>
  </div>);
};

export default SellerBatchFilter;
