export type ShippingBoxStatus =
  | "RETURNED"
  | "UNIT_RETURNED"
  | "CHECKIN"
  | "CHECKOUT"
  | "MOVINGTOUNIT"
  | "UNITARRIVED"
  | "CLAIMED"
  | "OUTFORDELIVERY"
  | "DELIVERYCOMPLETED"
  | "RETURNLATER";

export type ShippingContainerStatus =
  | "CHECKIN"
  | "CHECKOUT"
  | "UNITARRIVED"
  | "CLAIMED"
  | "OUTFORDELIVERY"
  | "DELIVERYCOMPLETED"
  | "UNIT_RETURNED"
  | "FINISHED";

export enum ShippingItemStatus {
  "CREATED" = "CREATED",
  "READYFORPICKUP" = "READYFORPICKUP",
  "PICKUPASSIGNED" = "PICKUPASSIGNED",
  "PICKUPCOMPLETED" = "PICKUPASSIGNED",
  "WAITINGFORSORT" = "WAITINGFORSORT",
  "BOXASSIGNED" = "BOXASSIGNED",
  "READYFORDROPOFF" = "READYFORDROPOFF",
  "READYFORDELIVERY" = "READYFORDELIVERY",
  "OUTFORDELIVERY" = "OUTFORDELIVERY",
  "DELIVERYINCOMPLETE" = "DELIVERYINCOMPLETE",
  "DELIVERYCOMPLETE" = "DELIVERYCOMPLETE",
  "RETURN_CREATED" = "RETURN_CREATED",
  "RETURN_BOXASSIGNED" = "RETURN_BOXASSIGNED",
  "RETURN_OUTFORCOLLECT" = "RETURN_OUTFORCOLLECT",
  "RETURN_NOTCOLLECTED" = "RETURN_NOTCOLLECTED",
  "RETURN_COLLECTED" = "RETURN_COLLECTED",
  "RETURN_WAITINGFORSORT" = "RETURN_WAITINGFORSORT",
  "RETURN_UNITARRIVED" = "RETURN_UNITARRIVED",
  "RETURN_TRANSITTOHUB" = "RETURN_TRANSITTOHUB",
  "RETURN_HUB" = "RETURN_HUB",
  "RETURN_BACKTOSELLER" = "RETURN_BACKTOSELLER",
  "RETURN_COMPLETE" = "RETURN_COMPLETE",
}

export type GetListParams = {
  page?: number;
  page_size?: number;
};

export type GetListResponse<T = any> = {
  count: number;
  next: string;
  previous: string;
  current_page: number;
  items_per_page: number;
  results: T[];
};

export type RefreshApiResponse = {
  refresh: string;
  access: string;
};

export type GenerateTokeApiResponse = {
  token: string;
  code: string;
  exp: string;
};

export type ConfirmCodeApiResponse = {
  access: string;
  refresh: string;
};

export type GetPickupRoutesAPIParam = {
  count: number;
  current_page: number;
  items_per_page: number;
  next: string;
  previous: null;
  results: [];
};

export type GetPlansAPIParam = {
  plan_name?: string;
  sector_code?: string;
  sort_code?: string;
  sort_equipment_id?: string;
  search?: string;
};

export type GetContainersAPIParam = {
  exclude_planned?: boolean;
  is_plan?: boolean;
  sector__code?: string;
  search?: string;
};

export type ContainerBatchAPIParams = {
  tracking_number: string;
  is_plan?: boolean;
  container_uuid?: string;
};

export type GetContainerDetailAPIParam = {
  box?: BoxType;
  claim_expire: string;
  container_class: ContainerType;
  count_box: number;
  count_deliveries: number;
  count_remaining: number;
  count_return: number;
  count_return_collected: number;
  count_shipping: number;
  count_shipping_incomplete: number;
  count_total_items: number;
  duration: number;
  lastmile_without_box: boolean;
  pickup_only: boolean;
  price: 1000;
  price_premium: 1000;
  push_assigned: boolean;
  sector: SectorType;
  status: ShippingBoxStatus;
  timestamp_checkin: string;
  timestamp_claimed: null;
  timestamp_finished: null;
  timestamp_manual_assigned: null;
  unit_location?: AddressDetailType;
  user?: UserAccountType;
  uuid: string;
};

export type UserType = "RIDER" | "SELLER" | "STAFF" | "ADMIN";

export enum BunnyType {
  FLEX = "FLEX",
  STAFF = "STAFF",
  FIXED = "FIXED",
}

export enum ContainerType {
  BLACK = "BLACK",
  BLUE = "BLUE",
  WHITE = "WHITE",
  OFFROAD = "OFFROAD",
  RAINBOW = "RAINBOW",
  YELLOW = "YELLOW",
  ORANGE = "ORANGE",
}

export interface RiderAccountType {
  rider_in_delivery_status: boolean;
  banned: boolean;
  fullname: string;
  is_active: boolean;
  is_available: boolean;
  permission_group: string[];
  phonenumber: string;
  type: UserType;
  username: string;
  bunny_type: BunnyType;
  ride_type: string;
  uuid: string;
}
export type UserAccountType = {
  dob: string;
  fullname: string;
  is_available: boolean;
  is_delivering: boolean;
  ride_type: string;
  bank_info_name: string;
  bank_info_bank_code: string;
  bank_info_account_number: string;
  ssn: string;
  helmet_verified: boolean;
  uuid: string;
  email: string;
  locale: string;
  phonenumber: string;
  username: string;
  status: string;
  type: UserType;
  bunny_type: BunnyType;
};

export type UpdateUserApiParam = {
  user?: {
    phonenumber: string;
  };
  ride_type?: string;
  bank_info_name?: string;
  bank_info_bank_code?: string;
  bank_info_account_number?: string;
};

export interface GetBoxStsApiResponse {
  num_box_return_later: number;
  num_box_return_ready: number;
  num_box_returned: number;
  num_box_terminated: number;
  num_box_total: number;
  num_box_working: number;
}

export interface GetContainerDispatchStsApiResponse {
  num_container_assign_all: number;
  num_container_assign_request_pending: number;
  num_container_assign_requested: number;
  num_container_assign_push_pending: number;
  num_container_assign_accept_pending: number;
  num_container_assign_accepted: number;
}

export interface GetContainerStsApiResponse {
  num_container_pending_close: number;
  num_item_pending_close: number;
  num_container_today: number;
  num_container_working: number;
  num_container_working_black: number;
  num_container_working_blue: number;
  num_container_working_white: number;
  num_container_working_pickup_only: number;
}

export interface GetShippingStsApiResponse {
  count_total: number;
  count_shipping: number;
  count_return: number;
}

export interface DamagedInvoiceType {
  timestamp_created: string;
  rider_phonenumber: string;
  item_alias: string;
  box_alias: string;
  tracking_number: string;
  receiver_name: string;
  identifier: string;
  status: string;
  has_shipping: boolean;
  shipping_tracking_number: string;
}

export interface GetLocationStsApiResponse {
  num_unit: number;
  num_hub: number;
}

export type CreatePlanMappingParam = {
  chute_id?: number;
  sector_code?: string;
  sort_code?: string;
  container_uuid: string;
  confirmed?: boolean;
};

export interface GetSectorApiResponse extends GetListResponse {
  results: SectorType[];
}

export interface GetShippingApiResponse extends GetListResponse {
  results: ShippingItemListType[];
}

export interface GetSellersApiResponse extends GetListResponse {
  results: SellerType[];
}
export interface GetUnitStatusApiResponse {
  num_assigned: number;
  num_delivery_completed: number;
  num_out_for_delivery: number;
  num_ready_for_delivery: number;
  num_ready_for_return_hub: number;
  num_ready_for_return_unit: number;
  num_total: number;
}

export interface UnitInfoProps extends UnitItemType {
  num_total: number;
  num_unitarrived: number;
  num_claimed: number;
  num_out_for_delivery: number;
  num_out_return_unit_available: number;
  num_out_delivery_completed: number;
  num_unit_returned: 0;
  index?: number;
}

export interface GetHubStatusApiResponse {
  num_box_return_later: number;
  num_box_return_ready: number;
  num_box_returned: number;
  num_box_terminated: number;
  num_box_total: number;
  num_box_working: number;
  num_collect_invoice: number;
  num_collect_invoice_done: number;
  num_collect_invoice_pending: number;
  num_container_assign_accept_pending: number;
  num_container_assign_accepted: number;
  num_container_assign_all: number;
  num_container_assign_push_pending: number;
  num_container_assign_request_pending: number;
  num_container_assign_requested: number;
  num_container_today: number;
  num_delivery_info_history: number;
  num_hub: number;
  num_item_return_pending_scan: number;
  num_item_shipping_pending_scan: number;
  num_rider_black: number;
  num_rider_blue: number;
  num_rider_white: number;
  num_unit: number;
}
export interface InvoiceType {
  currentPage: number;
  totalPage: number;
  uuid: string;
  alias: string;
  tracking_number: string;
  tracking_number_return: string;
  collect_invoice_identifier: string;
  order?: {
    order_number: string;
    product_info: string;
    shipping_memo: string;
    shipping_fee_type: "PRE_PAYMENT" | "COD" | "FREE";
    total_item_amount: number;
    total_shipping_fee: number;
    orderer_name: string;
    orderer_mobile_tel: string;
    timestamp_purchased: string;
    timestamp: string;
  };
  designated_sector?: {
    id: string;
    name: string;
    code: string;
  };
  is_return: false;
  mobile_tel: string;
  extra_memo: string;
  shop_name: string;
  shop_mobile: string;
  product_info: any[];
  shop_address?: AddressDetailType;
  address?: {
    name: string;
    mobile_tel: string;
    address_road: string;
    address_jibun: string;
    address1: string;
    address2: string;
    zipcode: string;
    sector?: {
      id: string;
      name: string;
      code: string;
      area: string;
    };
  };
}
export interface ShippingType {
  alias: string;
  extra_memo: string;
  is_return: boolean;
  mobile_tel: string;
  status: ShippingItemStatus;
  timestamp_delivery_complete: string;
  timestamp_delivery_incomplete: string;
  timestamp_return_complete: string;
  timestamp_return_incomplete: string;
  tracking_number: string;
  shipping_container?: {
    box?: {
      alias: string;
    };
  };
  uuid: string;
}

export type SectorTypeV2 = {
  designated_sector__code: string;
  count: number;
};

export type SectorType = {
  id: string;
  code: string;
  name: string;
  area: string;
  pending_shipping_item_count?: number;
  pending_return_item_count?: number;
};

export type GetShippingStatusAPIParams = {
  designated_sector__code?: string;
  area?: string;
  container_status?: string;
  order__shop__name?: string;
  completed?: boolean;
  status?: string | string[];
};

export interface GetSectorsAPIParams extends GetListParams {
  is_return?: boolean;
  status__in?: any[];
  completed?: boolean;
  ordering?: string;
  designated_sector__code__in?: any[];
  designated_sector__name__in?: any[];
  pickup_batch__uuid__in?: any[];
  shipping_container__uuid?: string;
  order__shop__name__in?: any[];
  search?: string;
}

export type GetShippingAPIParams = {
  is_return?: boolean;
  status?: ShippingBoxStatus | string[] | string;
  completed?: boolean;
  designated_sector__code?: string | string[];
  designated_sector__name?: string | string[];
  designated_sector__area?: string;
  shipping_container__uuid?: string;
  order__shop__name?: string[];
  search?: string;
  lat_max?: number;
  lng_max?: number;
  lat_min?: number;
  lng_min?: number;
  page_size?: number;
  pickup_round?: "FIRST" | "SECOND" | "THIRD";
  pickup_day_offset?: "0" | "-1";
  shipping_container__user__uuid?: string;
  shipping_container__box__uuid?: string;
  filter_status?: "HUB_WORKING" | "HUB_BEFORE_SORTING" | "HUB_AFTER_SORTING";
};

export type TZipcodeItem = {
  zipcode: string;
  coords: string;
  min_x: string;
  min_y: string;
  max_x: string;
  max_y: string;
  metadata: {
    property1: null;
    property2: null;
  };
  coords_dict: {
    property1: null;
    property2: null;
  };
};

export type TCoord = {
  lat: number;
  lng: number;
};
export type TSectorItem = {
  id: number;
  code: string;
  name: string;
  name_category: string;
  area: string;
  description: string;
  min_x: string;
  min_y: string;
  max_x: string;
  max_y: string;
}
export type TCoordinatesItem = {
  id: number;
  code: string;
  name: string;
  name_category: string;
  area: string;
  description: string;
  min_x: string;
  min_y: string;
  max_x: string;
  max_y: string;
  has_zipcode: boolean;
  unit_storages_dict: [
    { address__lat: number; address__lng: number; id: number; name: string }
  ];
  allowed: false;
  coords: TCoord[];
  max_items_per_cluster: number;
  metadata: {};
  preference: boolean;
  recursion_max: number;
  zipcodes: string[];
};

export interface IGetCoordinatesApiParams extends GetListParams {
  allowed?: boolean;
  area?: string;
  code?: string;
  code__startswith?: string;
  max_x?: string;
  max_y?: string;
  min_x?: string;
  min_y?: string;
  name?: string;
  name__icontains?: string;
  name_category__icontains?: string;
}
export interface GetShippingAPIParams2 extends GetListParams {
  is_return?: boolean;
  status__in?: any[];
  completed?: boolean;
  designated_sector__code__in?: any[];
  designated_sector__name__in?: any[];
  shipping_container__uuid?: string;
  shipping_timestamp_difference_in_minute?: number; //now  -  selected datetime
  shipping_timestamp_difference_end_in_minute?: number; //now  -  selected end datetime
  order__shop__name__in?: any[];
  pickup_batch__uuid__in?: any[];
  shipping_shop__uuid__in?: any[];
  return_order__shop__uuid__in?: any[];
  search?: string;
  lat_max?: number;
  lng_max?: number;
  lat_min?: number;
  lng_min?: number;
  pickup_round?: "FIRST" | "SECOND" | "THIRD";
  pickup_day_offset?: "0" | "-1";
  shipping_container__user__uuid?: string;
  shipping_container__box__uuid?: string;
  filter_status?: "HUB_WORKING" | "HUB_BEFORE_SORTING" | "HUB_AFTER_SORTING";
}

export type SellerType = {
  order__shop__name: string;
  shop_count: number;
};

export type SellerListType = {
  active: true;
  api_key: string;
  api_secret_key: string;
  daas_api_key: string;
  name: string;
  type: string;
  uuid: string;
};

export type SellerBatchType = {
  uuid: string;
  completed: boolean;
  timestamp_created: string;
  is_return: boolean;
  shipping_count: number;
  return_count: number;
  seller_name: string;
  round: string;
};

export type BoxType = {
  uuid: string;
  alias: string;
  status: ShippingBoxStatus;
};

export type HubItemType = {
  name: string;
  address: AddressDetailType;
  id: number;
};

export type UnitItemType = {
  name: string;
  address: AddressDetailType;
  sector_codes: string;
  id: string;
};

export type LogicalBoxType = {
  box?: BoxType;
  sector?: SectorType;
  container?: {
    is_plan?: boolean;
    uuid: string;
    unit_storage: string;
    status: ShippingBoxStatus;
    item_count: number;
    count_box: number;
    checked_in_shipping_item_count: number;
    checked_in_return_item_count: number;
    pending_shipping_item_count: number;
    pending_return_item_count: number;
  };
};

export type AddressDetailType = {
  name: string;
  address_road: string;
  address_jibun: string;
  address1: string;
  address2: string;
  lat: string;
  lng: string;
  zipcode: string;
  mobile_tel: string;
  sector?: SectorType;
};

export type DeliveryWorkingType = {
  count_deliveries: number;
  count_return: number;
  count_return_collected: number;
  count_shipping: number;
  count_shipping_incomplete: number;
  count_shipping_items: number;
  claim_expire: string;
  duration: string;
  price: number;
  sector?: SectorType;
  status: ShippingBoxStatus;
  timestamp_claimed: string;
  unit_location?: AddressDetailType;
  box?: BoxType;
  uuid: string;
};

export type ShippingItemListType = {
  address?: AddressDetailType;
  alias: string;
  seller_name: string;
  designated_sector?: SectorType;
  extra_memo: string;
  is_return: boolean;
  complete: boolean;
  highlighted: boolean;
  hovered: boolean;
  sorter_result: boolean;
  mobile_tel: string;
  status: ShippingBoxStatus;
  shipping_container?: ContainerApiResponse;
  timestamp_created: string;
  timestamp_delivery_complete: string;
  timestamp_delivery_incomplete: string;
  timestamp_return_collected: string;
  timestamp_return_complete: string;
  timestamp_return_created: string;
  timestamp_return_incomplete: string;
  tracking_number: string;
  uuid: string;
};

export type ShippingContainerType = {
  address?: AddressDetailType;
  alias: string;
  extra_memo: string;
  is_return: boolean;
  mobile_tel: string;
  status: ShippingBoxStatus;
  shipping_container?: ContainerApiResponse;
  timestamp_boxassigned: string;
  timestamp_pickup: string;
  tracking_number: string;
  timestamp_created: string;
  timestamp_return_complete: string;
  timestamp_return_created: string;
  unit_name: string;
  uuid: string;
};

export type ShippingItemType = {
  uuid: string;
  tracking_number: string;
  alias: string;
  is_return: boolean;
  complete: boolean;
  shipping_count: number;
  return_count: number;
  status: ShippingItemStatus;
  address: AddressDetailType;
};

export type ShippingInContainerType = {
  uuid: string;
  tracking_number: string;
  alias: string;
  is_return: boolean;
  complete: boolean;
  shipping_count: number;
  return_count: number;
  status: ShippingItemStatus;
  address: AddressDetailType;

  delivery_complete_on: string;
  extra_memo: "memo";
  hub_name: null;
  key: string;
  mobile_tel: string;
  photo?: string;
  product_info: "N/A";
  reason_delivery_change: string;
  receiver_address: string;
  receiver_name: string;
  return_sender_address: string;
  return_sender_name: string;
  shop_address: string;
  shop_name: string;
  timestamp_boxassigned: string;
  timestamp_pickup: string;
  unit_name: string;
};

export type GetUserApiResponse = {
  permission_group: string[];
  fullname: string;
  user: UserType;
};

export type EntryLogApiResponse = {
  address: string;
  address_id: number;
  delivery_box_number: string;
  delivery_box_password: string;
  delivery_complete_on: string;
  delivery_complete_on_info: string;
  entrance_option: string;
  entrance_password: string;
  id: number;
  photo: null;
  save_to_addr: false;
  timestamp: string;
  user?: UserAccountType;
};

export interface ShippingsInContainerApiResponse extends GetListResponse {
  results: ShippingItemType[];
}

export interface ContainerListApiResponse extends GetListResponse {
  results: ContainerApiResponse[];
}

export type ContainerBoxListApiParams = {
  sector__id?: string;
  sector__code?: string;
  is_plan?: boolean;
  box__alias?: string;
  ordering?: string;
};

export type CheckoutBoxApiParams = {
  box_uuid: string;
}[];

export type CheckinBoxApiParams = {
  sector_id?: string;
  is_plan?: boolean;
}[];

export type ContainerListApiParams = {
  search?: string;
  sector_code?: string;
  container_class?: string;
  status?: string;
};

export interface IGetPlanLongAPIParam extends GetListParams {
  chute_id?: number;

  cluster_id?: number | string;
  cluster_id__startswith?: string;
  cluster_id_startswith_in_csv?: string;
  chute_id__in?: number[];
  id__gt?: number;
  ordering?: string;
  plan_date: string;
  plan_no?: string | number;
  plan_no__in?: number[];
  sector_code?: string;
  sector_code__in?: string[];
  synced?: boolean;
}

export type TShippingGeo = {
  shipping_item__address__lat: number;
  shipping_item__address__lng: number;
  shipping_item__tracking_number: string;
  shipping_item__status: string;
  is_scanned: boolean;
};

export type TShippingItemInPlan = {
  id: number;
  tracking_number: string;
  shipping_item_status: ShippingBoxStatus;
  cluster_plan: {
    id: number;
    shipping_item_count: number;
    plan_date: string;
    plan_no: number;
    chute_id: number;
    synced: boolean;
    manual_checkin: boolean;
    count_return_not_boxed: number;
    count_return: number;
    container_alias: string[];
    container_uuids: string[];
    sub_category: string;
    chute_display: string;
    plan_display: string;
  };
  item_alias: string;
  is_scanned: boolean;
  is_received: boolean;
  is_manual_scan: boolean;
  timestamp: string;
  timestamp_updated: string;
  shipping_item: number;
};

export type TClusterPlanItem = {
  id: number;
  shipping_item_count: number;
  count_scanned_all: number;
  plan_date: string;
  plan_no: string;
  chute_id: number;
  cluster_id: string;
  synced: boolean;
  manual_checkin: boolean;
  container_alias: [];
  sub_category: string;
  shippingitems: string[];
  plan_status: string;
  count_checkedin: string;
  count_scan_manual: string;
  chute_display: string;
  plan_display: string;
  bunny_color: any;
};

export interface IClusterPlanLongItem extends TClusterPlanItem {
  shippingitems_geo: TShippingGeo[];
  shippingitems_scanned: string[];
}

export type PlanApiResponse = {
  id: number;
  plan_name: string;
  sort_equipment_id: string;
  chute_id: number;
  timestamp_created: string;
  sector_code: string;
  sort_code: string;
  unit_name: string;
  container_uuid: string;
  count_box: number;
  confirmed: boolean;
  dropoff_batch_assigned: boolean;
  count_items: number;
  count_confirmed: number;
};

export type SortStstApiResponse = {
  count_items_checkin: number;
  count_container_checkout: number;
  count_plan_before_sync: number;
  count_plan_before_confirm: number;
};

export type ContainerApiResponse = {
  box?: BoxType;
  claim_expire: string;
  container_class: ContainerType;
  count_deliveries: number;
  count_remaining: number;
  count_return: number;
  count_return_collected: number;
  count_shipping: number;
  count_shipping_incomplete: number;
  count_total_items: number;
  duration: number;
  lastmile_without_box: boolean;
  push_assigned: boolean;
  pickup_only: boolean;
  is_plan: boolean;
  price: number;
  price_premium: number;
  sector?: SectorType;
  status: ShippingContainerStatus;
  timestamp_checkin: string; //생성일
  timestamp_claimed: string; //수락일
  timestamp_finished: string;
  timestamp_manual_assigned: string; //요청
  unit_location?: AddressDetailType;
  user?: RiderAccountType;
  uuid: string;
};

export type PickupRouteApiParams = {
  alias?: string;
  date_end?: string;
  date_start?: string;
  page?: number;
  page_size?: number;
};

export type EntryUpdateApiParams = {
  delivery_box_number?: string;
  delivery_box_password?: string;
  delivery_complete_on?: string;
  delivery_complete_on_info?: string;
  entrance_option?: string;
  entrance_password?: string;
  photo?: string;
  save?: boolean;
};
