export const queries = {
  plan: {
    long: "/v1/hub/cluster_plans/long/",
    short: "/v1/hub/cluster_plans/",
    items: (id: number) => `/v1/hub/cluster_plans/${id}/items/`,
    merge: (id: number) => `/v1/hub/cluster_plans/${id}/merge/`,
    shift: "/v1/hub/cluster_plans/items/update/",
    create: `/v1/hub/cluster_plans/items/recreate/`,
  },
  location: {
    coordinate: "/v1/location/sectors/coordinate/",
    zipcode: "/v1/location/zipcodemeta/",
    sectorName: "/v1/location/sectors/name",
    sectors: "/v1/location/sectors?allowed=1&&page_size=1000",
  },
  shipping: {
    sectors: "/v2/hub/shippingitems/sectors",
    batches: "/v1/hub/pickupbatches/",
    shops: "/v1/shop/shops/",
  },
};
