import {fetcher} from "../services/http.service";
import {GetListResponse} from "./apiTypes";
import {useEffect, useState} from "react";
import useSWRInfinite from "swr/infinite";
import {useSelector} from "react-redux";
import {objToQueryString} from "../fns/commonFns";
import {RootState} from "../reducers/reducer";

const PAGE_SIZE = 100;
export const useDataAll = <T, P = any>(url: string, params?: P) => {
  const {authenticated} = useSelector((state: RootState) => state.auth);
  const [listData, setListData] = useState<T[]>([]);
  const getKey = (pageIndex: number, previousPageData: GetListResponse<T>) => {
    console.log("useSellerBatches*** getKey ", pageIndex, params);
    if (authenticated && pageIndex === 0) {
      const paramString = objToQueryString(params, true)
      return (url + `?page_size=${PAGE_SIZE}&${paramString}`);
    }
    if (previousPageData && previousPageData.next) return previousPageData.next;
    return null; // reached the end
  };
  
  const {data, error, setSize, isValidating, mutate} = useSWRInfinite<GetListResponse<T>>(getKey, fetcher, {
    initialSize: 1, revalidateOnFocus: false, revalidateIfStale: false, revalidateFirstPage: false,
  });
  
  console.log("render**** useSellerBatches", data, error, listData);
  useEffect(() => {
    if (data && data?.length > 0) {
      const lastRes = data[data?.length - 1];
      console.log("batches 1", data, lastRes);
      if (lastRes && lastRes.current_page * lastRes.items_per_page < lastRes.count) {
        setSize((size) => size + 1);
      }
      else {
        let listData: T[] = [];
        data.forEach((item) => {
          if (item?.results) {
            listData = listData.concat(item.results);
          }
        });
        setListData(listData);
      }
    }
  }, [data]);
  
  return {
    listData, error, isLoading: isValidating, mutate
  };
};
