import React, {forwardRef} from "react";
import styles from "./filter.module.css";

import {Checkbox} from "antd";
import {SellerListType} from "../../../api/apiTypes";
import InfiniteList from "../../molecules/list/InfiniteList";
import {CheckboxGroupProps} from "antd/es/checkbox";

interface Props extends CheckboxGroupProps {
  onSearch?: (value: string[]) => void;
}

const SellerFilter = forwardRef(({onSearch, value}: Props, ref) => {
  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("checked = ", checkedValues);
    !!onSearch && onSearch(checkedValues);
  };
  
  return (<InfiniteList
    className={styles.container}
    url={"/v1/shop/shops/"}
    params={{ordering: "name"}}
    style={{overflowX: "hidden"}}
  >
    {(count?: number, sectors?: SellerListType[], isLoading?) => {
      return (<Checkbox.Group
        value={value}
        className={styles.checkGroup + " column"}
        onChange={handleChangeChecked}
      >
        {sectors && sectors.length > 0 ? sectors.map((s, idx) => (<Checkbox
          key={"chek" + idx}
          className={styles.check}
          data-cy="homeManageCheckbox"
          value={s.uuid}
        >
          {s.name}
        </Checkbox>)) : isLoading || "데이터가 없습니다"}
      </Checkbox.Group>);
    }}
  </InfiniteList>);
});

export default SellerFilter;
