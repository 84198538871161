import React, {useMemo} from "react";
import styles from "./filter.module.css";

import {Checkbox, CheckboxProps} from "antd";
import {CheckboxGroupProps} from "antd/es/checkbox";
import {useDataAll} from "../../../api/useDataAll";
import {queries} from "../../../api/queries";
import {useTranslation} from "react-i18next";
import {SellerListType} from "../../../api/apiTypes";
import Lottie from "lottie-react";
import spinner from "../../../assets/lotties/spinner.json";

interface Props extends CheckboxGroupProps {
  onSearch?: (value: string[]) => void;
}

const ReturnSellerFilter = ({onSearch, value}: Props) => {
  const {t} = useTranslation(["shipping"]);
  const {listData, isLoading} = useDataAll<SellerListType>(queries.shipping.shops, {
    allow_return: '1',
    ordering: "name"
  });
  const allUuid = useMemo(() => {
    if (listData) {
      return listData.map((batch) => batch.uuid);
    }
    return [];
  }, [listData]);
  const checkAll = value && listData?.length > 0 && listData?.length === value.length;
  
  const handleChangeChecked = (checkedValues: any[]) => {
    console.log("checked = ", checkedValues);
    !!onSearch && onSearch(checkedValues);
  };
  
  const handleCheckAll: CheckboxProps['onChange'] = (e) => {
    !!onSearch && onSearch(e.target.checked ? allUuid : []);
  };
  
  return (<div className={"column flex"}>
    <div className={"row space-between filterTitle"}>
      <span className={"body black85 "}>{t("return shop")}</span>
      <Checkbox className={styles.checkAll} onChange={handleCheckAll}
                checked={checkAll}>
        전체
      </Checkbox>
    </div>
    <Checkbox.Group
      className={styles.container + " column " + styles.checkGroup}
      value={value}
      onChange={handleChangeChecked}
    >
      {listData && listData.length ? listData.map((s, idx) => (<Checkbox
        key={"chek" + idx}
        className={styles.check}
        data-cy="homeManageCheckbox"
        value={s.uuid}
      >
        {s.name}
      </Checkbox>)) : (isLoading ? <Lottie autoplay animationData={spinner} loop style={{height: 25}} /> : <span className={styles.nodata}>데이터가 없습니다</span>)}
    </Checkbox.Group>
  </div>);
};

export default ReturnSellerFilter;
